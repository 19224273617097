import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import { Fade } from "react-reveal"

export default () => {
  const img = useStaticQuery(graphql`
    query expertimgfintech {
      image1: file(relativePath: { eq: "expertise-fintech.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image2: file(relativePath: { eq: "expertise-buyside.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image3: file(relativePath: { eq: "expertise-markets.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image4: file(relativePath: { eq: "expertise-advisories.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image5: file(relativePath: { eq: "expertise-investment.png" }) {
        childImageSharp {
          fluid(quality: 100, maxHeight: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image6: file(relativePath: { eq: "expertise-software.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div
      className={`container mt-20 px-2 flex justify-between items-center mx-auto`}
    >
      <div className={"w-full"}>
        <Fade>
          <h2 className=" mb-2 heading-center">
            {" "}
            Our expertise<span className={"red"}>.</span>
          </h2>
        </Fade>
        <Fade>
          <h4 className={"text-lg lg:text-xl mb-4 text-center"}>
            We cover Front, Middle and Back Office roles with leaders in
            FinTech.
          </h4>
        </Fade>
        <div className={"grid md:grid-cols-2 lg:grid-cols-3"}>
          <Card
            title={"FinTech"}
            img={img.image1}
            p1={"Payments"}
            p2={"Blockchain"}
            p3={"Open Banking"}
          />
          <Card
            title={"Buy Side"}
            img={img.image2}
            p1={"Asset Management"}
            p2={"Wealth Management"}
            p3={"Hedge Funds"}
          />
          <Card
            title={"Markets"}
            img={img.image3}
            p1={"Exchange"}
            p2={"Brockerage Houses"}
            p3={"Market Makers"}
          />
          <Card
            title={"Advisories"}
            img={img.image4}
            p1={"Consultancies"}
            p2={"Big 4"}
            p3={"LLPs"}
          />
          <Card
            title={"Investment Banking"}
            img={img.image5}
            p1={"Commodities"}
            p2={"Trading"}
            p3={"Tier 1/2/3"}
          />
          <Card
            title={"Software Vendors"}
            img={img.image6}
            p1={"Trading Technology"}
            p2={"RegTech"}
            p3={"SAAS Solutions"}
          />
        </div>
      </div>
    </div>
  )
}

const Card = ({ img, title, p1, p2, p3 }) => {
  return (
    <div
      style={{
        backgroundColor: "#11111a",
      }}
      className={
        "flex p-3 m-2  border-secondary border rounded-sm flex-1 justify-between align-middle items-center	"
      }
    >
      <div className={"flex-1 flex justify-center"}>
        <Img
          className={"min-w-12 w-20 max-h-24"}
          loading={"eager"}
          fluid={img.childImageSharp.fluid}
        />
      </div>
      <div className={"flex-1"}>
        <div className={"font-bold text-xl"}>{title}</div>
        <ul className={" font-light custombullet ml-4"}>
          <li>{p1}</li>
          <li>{p2}</li>
          <li>{p3}</li>
        </ul>
      </div>
    </div>
  )
}

Card.propTypes = {
  img: PropTypes.any,
  title: PropTypes.string,
  p1: PropTypes.string,
  p2: PropTypes.string,
  p3: PropTypes.string,
}
