import React from "react"
import Fade from "react-reveal"
import CountUp from "react-countup"
import VisibilitySensor from "react-visibility-sensor"

export default () => {
  return (
    <>
      <div
        className={`container mt-20 lg:mt-24 flex justify-between items-center mx-auto`}
      >
        <div className={"w-full"}>
          {" "}
          <Fade>
            <h2 className="lg:mb-2 heading-center mx-4">
              {" "}
              The Referment difference<span className={"red"}>.</span>
            </h2>
          </Fade>
          <div className={"flex lg:flex-row flex-col"}>
            <div
              className={" rounded p-6 m-6 flex-1 flex-col flex text-center"}
            >
              <div className={"text-3xl font-bold"}>Crowd-Sourced</div>
              <div className={"text-secondary mb-3 font-bold text-5xl py-10"}>
                <CountUp end={40000}>
                  {({ countUpRef, start }) => (
                    <VisibilitySensor onChange={start} delayedCall>
                      <span ref={countUpRef} />
                    </VisibilitySensor>
                  )}
                </CountUp>
              </div>
              <div className={"body-text body-text-center "}>
                Our growing network of over 40,000 industry professionals engage
                their networks to identify suitable people.
              </div>
            </div>
            <div
              className={" rounded p-6 m-6 flex-1 flex-col flex text-center"}
            >
              <div className={"text-3xl font-bold"}>Untapped</div>
              <div className={"text-secondary mb-3 font-bold text-5xl py-10"}>
                <CountUp end={65}>
                  {({ countUpRef, start }) => (
                    <VisibilitySensor onChange={start} delayedCall>
                      <span ref={countUpRef} />
                    </VisibilitySensor>
                  )}
                </CountUp>
                %
              </div>
              <div className={"body-text body-text-center "}>
                Crowd-sourced referrals meant that 65% of our placements last
                year weren’t actively looking for a new role.
              </div>
            </div>

            <div
              className={" rounded p-6 m-6 flex-1 flex-col flex text-center"}
            >
              <div className={"text-3xl font-bold"}>Game-changers</div>
              <div className={"text-secondary mb-3 font-bold text-5xl py-10"}>
                <CountUp end={95}>
                  {({ countUpRef, start }) => (
                    <VisibilitySensor onChange={start} delayedCall>
                      <span ref={countUpRef} />
                    </VisibilitySensor>
                  )}
                </CountUp>
                %
              </div>
              <div className={"body-text body-text-center "}>
                95% of all candidates receive a first-round interview.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
