import React from "react"

import Layout from "../components/layout/index"
import SEO from "../components/seo"

import { HireSlider } from "../components/homepageComponents/titlePage"
import ClientReview from "../components/homepageComponents/clientsReview"

import UnTapped from "../components/clientPageComponents/unTapped"
import TechDriven from "../components/clientPageComponents/techDriven"
import Countup from "../components/clientPageComponents/countUp"
import Expertise from "../components/clientPageComponents/experiseList"
import Location from "../components/clientPageComponents/location"
import Email from "../components/clientPageComponents/email"

const ClientPage = props => {
  const [showNav, setShowNav] = React.useState(false)

  const onScroll = e => {
    if (e.target.scrollTop > 100) {
      setShowNav(true)
    } else {
      setShowNav(false)
    }
  }

  const [currency, setCurrency] = React.useState("uk")

  React.useEffect(() => {
    setCurrency(props.pathContext.currency || "uk")
  }, [])

  return (
    <>
      <SEO
        title="Clients"
        description={
          "Supercharge your talent acquisition with Referment, the FinTech Services recruitment experts"
        }
      />
      <Layout
        isBase={!props.pathContext.currency}
        showNav={showNav}
        page={"clients"}
        currency={currency}
      >
        <div onScroll={e => onScroll(e)} className={""}>
          <section class="snap-child">
            <HireSlider />
          </section>

          <section class="snap-child">
            <UnTapped currency={currency} />
          </section>

          <section class="snap-child">
            <TechDriven />
          </section>

          <section class="snap-child">
            <ClientReview />
          </section>

          <section class="snap-child">
            <Countup />
          </section>

          <section class="snap-child">
            <Expertise />
          </section>

          <section class="snap-child">
            <Location />
          </section>

          <section class="snap-child">
            <Email />
          </section>
        </div>
      </Layout>
    </>
  )
}

export default ClientPage
