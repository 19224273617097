import React from "react"
import Fade from "react-reveal"

export default () => {
  return (
    <>
      <div
        id={"contact"}
        className={`container flex justify-between items-center mt-20 lg:mt-0 mb-10 lg:pt-24 mx-auto`}
      >
        <div className={"w-full"}>
          <h2 className="mb-4 heading-center">
            {" "}
            Contact us<span className={"red"}>.</span>
          </h2>
          <div class="min-w-full  place-items-center">
            <div class="w-11/12 ml-auto mr-auto border-primaryDark border-4 bg-primaryLight lg:p-10 p-4  pt-4 bg-white sm:w-8/12 md:w-2/3 lg:w-10/12">
              <form
                name="Contact"
                method="POST"
                data-netlify="true"
                class="mt-6 lg:grid grid-cols-2 gap-8"
              >
                <input type="hidden" name="form-name" value="Contact" />
                <div>
                  <label
                    for="firstname"
                    class="block text-xs font-semibold text-gray-600 uppercase"
                  >
                    Name
                  </label>
                  <input
                    id="firstname"
                    type="text"
                    name="firstname"
                    placeholder="John"
                    autocomplete="given-name"
                    class="block w-full p-3 mt-2 text-primary bg-gray-200 appearance-none focus:outline-none focus:bg-gray-300 focus:shadow-inner"
                    required
                  />
                  <label
                    for="email"
                    class="block mt-2 text-xs font-semibold text-gray-600 uppercase"
                  >
                    Email
                  </label>
                  <input
                    id="email"
                    type="email"
                    name="email"
                    placeholder="Email"
                    autocomplete="email"
                    class="block w-full p-3 mt-2 text-primary bg-gray-200 appearance-none focus:outline-none focus:bg-gray-300 focus:shadow-inner"
                    required
                  />
                  <label
                    for="number"
                    class="block mt-2 text-xs font-semibold text-gray-600 uppercase"
                  >
                    Contact Number
                  </label>
                  <input
                    id="number"
                    type="text"
                    name="number"
                    placeholder="Contact number"
                    autocomplete="number"
                    class="block w-full p-3 mt-2 text-primary bg-gray-200 appearance-none focus:outline-none focus:bg-gray-300 focus:shadow-inner"
                    required
                  />
                  <label
                    for="number"
                    class="block mt-2 text-xs font-semibold text-gray-600 uppercase"
                  >
                    Organisation
                  </label>
                  <input
                    id="org"
                    type="text"
                    name="org"
                    placeholder="Organisation"
                    autocomplete="number"
                    class="block w-full p-3 mt-2 text-primary bg-gray-200 appearance-none focus:outline-none focus:bg-gray-300 focus:shadow-inner"
                    required
                  />
                </div>
                <div>
                  <label
                    for="number"
                    class="block mt-2 lg:mt-0 text-xs font-semibold text-gray-600 uppercase"
                  >
                    Message
                  </label>
                  <textarea
                    id="message"
                    type="textarea"
                    name="message"
                    placeholder="Message"
                    rows={8}
                    class="block w-full p-3 mt-2 text-primary bg-gray-200 appearance-none focus:outline-none focus:bg-gray-300 focus:shadow-inner"
                    required
                  />
                  <button
                    type="submit"
                    class="w-1/2 ml-auto block py-3 mt-6 font-medium tracking-widest text-primary uppercase bg-secondary shadow-lg focus:outline-none hover:bg-gray-900 hover:shadow-none"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
