import React, { useState, useEffect } from "react"
import styles from "./index.module.css"
import Fade from "react-reveal/Fade"
import { Link } from "gatsby"
import Slider from "react-slick"
import { getCurrency } from "../../utils"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import scrollTo from "gatsby-plugin-smoothscroll"
import { getUrl } from "../../header"

export default ({ currency }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: false,
    autoplaySpeed: 7000,
  }

  const [height, setHeight] = useState("100vh")

  useEffect(() => {
    if (typeof window !== "undefined") {
      setHeight(window.innerHeight)
    }
  }, [])

  return (
    <>
      <div
        style={{
          height: typeof window !== "undefined" ? window.innerHeight : "100vh",
        }}
        className={`${styles.main}`}
      >
        <div
          style={{
            marginTop:
              typeof window !== "undefined"
                ? `${window.innerHeight * 0.5}px`
                : "50vh",
          }}
          className={`${styles.align}`}
        >
          <Slider {...settings}>
            <Refer currency={currency} />
            <Apply />
            <Hire />
          </Slider>
        </div>
      </div>
    </>
  )
}

export const HireSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: false,
    autoplaySpeed: 5000,
  }

  return (
    <>
      <div
        style={{
          height: typeof window !== "undefined" ? window.innerHeight : "100vh",
        }}
        className={`${styles.main}`}
      >
        <div
          style={{
            marginTop:
              typeof window !== "undefined"
                ? `${window.innerHeight * 0.5}px`
                : "50vh",
          }}
          className={`${styles.align}`}
        >
          <Slider {...settings}>
            <ClientHire />
          </Slider>
        </div>
      </div>
    </>
  )
}

const Refer = ({ currency }) => (
  <div className={styles.innerContainer}>
    <h2 className={styles.backgroundText}>Refer</h2>
    <h1 className={`${styles.title}`}>
      Refer people you know to new jobs and earn up to{" "}
      {getCurrency(currency, 500)} when they interview
      <span className={"red"}>.</span>
    </h1>
    <h6 className={`${styles.body}`}>
      Connect untapped talent with career-defining roles
    </h6>
    <a
      href={"https://app.referment.com"}
      rel="noopener noreferrer"
      target="_blank"
      className={"btn-lg relative mt-8"}
    >
      Refer Now
    </a>
  </div>
)

const Apply = () => (
  <div className={styles.innerContainer}>
    <h2 className={styles.backgroundText}>Apply</h2>
    <h1 className={`${styles.title}`}>
      Apply to exclusive FinTech roles
      <span className={"red"}>.</span>
    </h1>
    <h6 className={`${styles.body}`}>
      Upload your CV to see your recommended job matches today
    </h6>
    <a
      rel="noopener noreferrer"
      target="_blank"
      href={"https://app.referment.com"}
      style={{ bottom: 0 }}
      className={"btn-lg relative mt-8"}
    >
      Apply Now
    </a>
  </div>
)

export const Hire = () => (
  <div className={styles.innerContainer}>
    <h2 className={styles.backgroundText}>Hire</h2>
    <h1 className={`${styles.title}`}>
      Supercharge your talent acquisition with Referment
      <span className={"red"}>.</span>
    </h1>
    <h6 className={`${styles.body}`}>
      Efficient and effective recruitment solutions for the FinTech industry
    </h6>
    <Link
      to={getUrl("clients")}
      onClick={() => scrollTo("#contact")}
      className={"btn-lg relative mt-8"}
    >
      Hire Now
    </Link>
  </div>
)

export const ClientHire = () => (
  <div className={styles.innerContainer}>
    <h2 className={styles.backgroundText}>Hire</h2>
    <h1 className={`${styles.title}`}>
      Supercharge your talent acquisition with Referment
      <span className={"red"}>.</span>
    </h1>
    <h6 className={`${styles.body}`}>
      Efficient and effective recruitment solutions for the FinTech industry
    </h6>
    <button
      onClick={() => scrollTo("#contact")}
      className={"btn-lg relative mt-8"}
    >
      Hire Now
    </button>
  </div>
)
