import React from "react"
import styles from "./index.module.css"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Fade from "react-reveal/Fade"

export default ({ currency }) => {
  const data = useStaticQuery(graphql`
    query untapped {
      uk: file(relativePath: { eq: "Referment-Main-Hero-Image-2021.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      au: file(relativePath: { eq: "Referment-Main-Hero-Image-2021-AU.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className={`container mx-auto my-10 md ${styles.main}`}>
      {/* <h2 className={"heading lg:hidden mx-8"}>
        Untapped talent, crowd-sourced from our FS community
        <span className={"red"}>.</span>
      </h2> */}
      <div className={`${styles.content}`}>
        <div
          className={
            " flex-col flex-1 mx-8 justify-center align-center my-auto"
          }
        >
          <div>
            <Fade>
              <h2 className={"heading-center"}>
                Untapped talent, crowd-sourced from our FS community
                <span className={"red"}>.</span>
              </h2>
            </Fade>
            <Fade>
              <div className={"mb-4"}>
                <p className={"body-text-center body-text mt-8"}>
                  We’ve curated an amazing community of over 40,000 industry professionals that refer people from
                  their network to your roles on our platform. We incentivise
                  them (at no cost to you) to introduce us to untapped talent in
                  their networks producing fantastic candidates that you
                  wouldn’t find with traditional recruitment.
                </p>
              </div>
            </Fade>
          </div>
        </div>
        <Fade>
          <div className={"flex-1 lg:my-auto my-10"}>
            <Img loading={"eager"} fluid={data[currency].childImageSharp.fluid} />
          </div>
        </Fade>
      </div>
    </div>
  )
}
