import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import { Fade } from "react-reveal"

export default () => {
  const img = useStaticQuery(graphql`
    query locationimage {
      image: file(relativePath: { eq: "locations.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <div
      className={
        "container  mt-20 lg:mt-24 pt-4 justify-center md:px-32  flex mx-auto flex-col"
      }
    >
      <Fade>
        <h2 className="heading-center">
          Our offices<span className={"red"}>.</span>
        </h2>
      </Fade>
      <div
        className={
          "flex items-center justfy-between flex-col-reverse md:flex-row"
        }
      >
        <div className={"flex-1 flex flex-col text-lg  "}>
          <div className={"text-secondary"}>Referment UK</div>
          <div>92 Albert Embankment</div>
          <div>Vauxhall, SE1 7TT</div>
          <div>London</div>
          <div>clientservices@referment.com</div>
          <div>+44 (0)203 948 1595</div>
          <br />
          <div className={"text-secondary"}>Referment APAC</div>
          <div>Suite 1102, Level 11, 50 Margaret </div>
          <div>Street, Sydney, NSW, 2000</div>
          <div>clientservices@referment.com</div>
          <div>+61 (0)422967025</div>
        </div>
        <Img
          className={"flex-1 max-w-sm md:m-20 m-10 w-56"}
          loading={"eager"}
          fluid={img.image.childImageSharp.fluid}
        />
      </div>
    </div>
  )
}
