import React from "react"
import styles from "./index.module.css"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Fade from "react-reveal"

export default () => {
  const data = useStaticQuery(graphql`
    query ApplywRoles {
      file(relativePath: { eq: "Network Graphic.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div
      className={`container mx-auto flex mb-20 lg:mb-0 pt-20 ${styles.main}`}
    >
      <div className={`my-auto mx-auto flex justify-between ${styles.content}`}>
        <Fade>
          <div className={"flex-1 mx-4 lg:mx-0 lg:mb-20 my-auto"}>
            <Img
              className={"lg:mr-20 mx-auto"}
              loading={"eager"}
              fluid={data.file.childImageSharp.fluid}
            />
          </div>
        </Fade>
        <div className={" flex-col flex-1 mx-8 justify-between lg:mt-20 my-10"}>
          <Fade>
            <h2 className={" mb-4  heading-center"}>
              Technology-driven, people-focused recruitment
              <span className={"red"}>.</span>
            </h2>
          </Fade>
          <Fade>
            <div className={"mb-4"}>
              <p className={"body-text body-text-center"}>
                We invest equally in our people and our technology. Each of our
                relationship managers has extensive experience in FinTech and we have a technology team building systems to
                optimise every element of the recruitment process.
              </p>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  )
}
